import 'react-lazy-load-image-component/src/effects/blur.css'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'

export interface Props {
  description?: string
  mainImage?: ImageProps
  subtitle?: string
  thumbnail?: ImageProps
  title?: string
  variant?: Variant
}

export const Intro = memo(function Intro({
  description,
  mainImage,
  subtitle,
  thumbnail,
  title,
  variant = 'default',
}: Props) {
  return (
    <Container
      className={!mainImage && !thumbnail ? 'simple' : undefined}
      row
      tag="section"
      variant={variant}
      wrap
    >
      <LeftSide>
        <Wrap className="intro-wrap">
          {title ? (
            <FadeInUp>
              <Title>{title}</Title>
            </FadeInUp>
          ) : null}
          {subtitle ? (
            <FadeInUp>
              <Subtitle>{subtitle}</Subtitle>
            </FadeInUp>
          ) : null}
        </Wrap>
        {mainImage ? (
          <MainImage>
            <LazyLoadImage effect="blur" {...mainImage} />
          </MainImage>
        ) : null}
      </LeftSide>
      <RightSide>
        {thumbnail ? (
          <Thumbnail>
            <LazyLoadImage effect="blur" {...thumbnail} />
          </Thumbnail>
        ) : null}
        {description ? (
          <FadeInUp>
            <Description
              className="intro-desc"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </FadeInUp>
        ) : null}
      </RightSide>
    </Container>
  )
})

const Container = styled(FlexBox)<ContainerProps>`
  margin-top: 9rem;
  &.simple {
    max-width: 45rem;
    margin: 20.625rem auto 11.25rem;
    > div {
      width: 100%;
      &:last-of-type {
        margin-top: 2.5rem;
      }
      .intro-wrap {
        padding-left: 0;
      }
      .intro-desc {
        padding-right: 0;
      }
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  ${({ variant }) => {
    switch (variant) {
      case 'full':
        return css`
          margin-top: 20.625rem;

          @media (max-width: 1199px) {
            margin-top: 9rem;
          }
          @media (max-width: 1023px) {
            margin-top: 5.625rem;
          }
        `
    }
  }}

  @media (max-width: 1199px) {
    &.simple {
      margin-top: 9rem;
    }
  }

  @media (max-width: 1023px) {
    margin-top: 6.25rem;
    margin-bottom: 5.625rem;
    &.simple {
      max-width: none;
      margin-top: 6.25rem;
      margin-bottom: 5.625rem;
    }
  }

  @media (max-width: 767px) {
    margin-top: 5.625rem;
    &.simple {
      margin-top: 5.625rem;
    }
  }
`

const LeftSide = styled.div`
  width: 62%;
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: 400;

  @media (max-width: 1023px) {
    width: 100%;
    padding: 0 1.875rem;
  }
`

const Wrap = styled.div`
  padding-left: 6.667vw;

  @media (max-width: 1023px) {
    padding: 0;
  }
`

const Title = styled.h2`
  font-size: 3.125rem;
  line-height: 4.25rem;
  margin-bottom: 1.125rem;

  @media (max-width: 1023px) {
    font-size: 2.5rem;
    line-height: 3.375rem;
  }
`

const Subtitle = styled.div`
  font-size: 1.25rem;
  line-height: 1.6875rem;
  padding-left: 3.75rem;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    width: 2.5rem;
    height: 0.125rem;
    background: ${({ theme }) => theme.colors.variants.neutralLight2};
    top: 0.75rem;
    left: 0;
  }

  @media (max-width: 767px) {
    padding-left: 2.5rem;
    &:before {
      content: '';
      width: 1.5rem;
    }
  }
`

const MainImage = styled.div`
  width: calc(100% - 12.708vw);
  margin-top: 6.625rem;
  padding-bottom: calc(100% - 12.708vw);
  position: relative;

  @media (max-width: 1023px) {
    display: none;
  }
`

const RightSide = styled.div`
  width: 38%;

  @media (max-width: 1023px) {
    width: 100%;
    margin-top: 2.5rem;
    padding: 0 1.875rem;
  }
`

const Thumbnail = styled.div`
  width: calc(100% - 3.681vw);
  margin: 0 0 6.25rem auto;
  padding-bottom: 68%;
  position: relative;

  @media (max-width: 1023px) {
    display: none;
  }
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark5};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.125rem;
  font-weight: 300;
  line-height: 2rem;
  padding-right: 3.333vw;

  p {
    margin-block-end: 1em;
    &:last-of-type {
      margin-block-end: 0;
    }
  }

  @media (max-width: 1023px) {
    font-size: 1rem;
    line-height: 1.75rem;
    padding-right: 0;
  }
`

interface ContainerProps {
  variant: Variant
}

export type Variant = 'default' | 'full'
